import React from "react"
import Iframe from "react-iframe"
import { Link } from "gatsby"

export default function Contact() {
  return (
    <>
      <section id="contact">
        <div className="container-fluid" style={{marginBottom: "20px",}}>
        <div className="col-lg-12 mb-6" style={{ backgroundColor: "#08263b", display: "contents", height: "150px", border: "none", marginTop: "20px", }}>
          <div className="card" data-aos="zoom-in" data-aos-delay="100" style={{ backgroundColor: "#08263b", color: "white", fontWeight: 400, }}>
            <div className="card-body">
              <h5 className="card-title" style={{ marginTop: "20px", marginBottom: "30px", textAlign: "center", }}>
              
                We’re an energetic, flexible, and open-minded team ready to work hard for our clients. If you’re interested in working with us, please send us a message or drop us an email on <b>info@asapient.com</b>
              
              </h5>
            </div>
            </div>
          </div>
          <div className="col-lg-12 row">
          <div className="col-lg-6">
              <div className="row">
                <div className="col-md-6 info" style={{marginTop: "38px",}}>
                  <i><img className="img-icon2" src="/assets/img/contact page icon/remcopy.png" alt="" /></i>
                 
                  <p className="contact-add"> India-Delhi Office<br/> 35 Link Road, Lajpat Nagar 3, <br/> New Delhi-110024</p>
                </div>
                <div className="col-md-6 info" style={{marginTop: "38px",}}>
                <i><img className="img-icon2" src="/assets/img/contact page icon/remcopy.png" alt="" /></i>
                <p className="contact-add"> USA Office<br/> 200 Continental Drive, <br/> Suite 401 Newark, DE 19713 (USA)</p>
                </div>
                
              </div>
              <div className="row">
                <img style={{ height: "480px", width: "400px"}} src="/assets/img/download/contactus.png" className="img-fluid" alt="" />
              
              </div>
            </div>
            <div className="col-lg-6">
              <div className="map mb-4 mb-lg-0">
                <iframe frameborder="0" style={{ height: "700px", width: "100%", border: "0px", }} src='https://forms.zohopublic.com/xccelerata1/form/ASApientContactUsForm/formperma/h-i-KBFxltNIApeJeB1dbA0xSfT5Pz6WWuxrWk1iCFc'></iframe>
              </div>
            </div>

            
          </div>
        </div>
      </section>
    </>
  )
}
